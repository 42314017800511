$(document).ready(function(){
	$(window).load(function(){

		// INITIATIONS
		// AOS.init();
		
		mobileLayout();
		cf7formsubmit();
		caretMenu();

		$('.loader-overlay').fadeOut(10);

	});

	/*$('.custom-slick-slider').on('init', function(e, slick) {
        var $firstAnimatingElements = $('div.slick-slide:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);  
    }); 
    $('.custom-slick-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
        var $animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
        doAnimations($animatingElements);    
    });*/

	$('.custom-slick-slider').slick({
		autoplaySpeed: $('.slider-option').data('slidespeed'),
		autoplay: $('.slider-option').data('autoplay'),
		dots: false,
		infinite: true,
		speed: 500,
		fade: $('.slider-option').data('fading'),
		arrows: true,
		dots: true,
   		dotsClass: 'custom_paging',
   		customPaging: function (slider, i) {
   			return '- 0'+(i + 1);
   		},
		cssEase: 'linear',
		narrows : false,
		nextArrow: '<div class="wrap right"><i class="fas fa-chevron-right"></i></div>',
		prevArrow: '<div class="wrap left"><i class="fas fa-chevron-left"></i></div>',
    });

    $('.product-category-listing').slick({
      dots: false,
      autoplaySpeed: 1500,
      autoplay: false,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      narrows : false,
	  nextArrow: '<div class="wrap right"><div class="wap"><i class="fas fa-chevron-right"></i></div></div>',
	  prevArrow: '<div class="wrap left"><div class="wap"><i class="fas fa-chevron-left"></i></div></div>',
      responsive: [
      	{
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
      ]
    });

    var selector1 = '.single-featured-image > .image-wrapper';
	var selector2 = '.color-listing > li > .colors-value';

    $( ".colors-value" ).each(function( index ) {

    	var $DATA = $(this).data('indecator');

    	$(this).on('click', function () {

    		$(selector1).removeClass('active');
    		$($DATA).addClass('active');

    		$(selector2).removeClass('active');
	    	$(this).addClass('active');
    	});
    });

});

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/

function doAnimations(elements) {
    var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    elements.each(function() {
        var $this = $(this);
        var $animationDelay = $this.data('delay');
        var $animationType = 'animated ' + $this.data('animation');
        $this.css({
            'animation-delay': $animationDelay,
            '-webkit-animation-delay': $animationDelay
        });
        $this.addClass($animationType).one(animationEndEvents, function() {
            $this.removeClass($animationType);
        });
    });
}

function pre( $object ){
	console.log( $object );
}

function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$('.get-overlay').toggleClass("overlay");
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.get-overlay').click(function(){
		$(this).toggleClass("overlay");
		$('.sidepanel').toggleClass("sidepanel-out" );
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
			$('.main-header').addClass('header-fixed');
		} else {
			$('.back-to-top').fadeOut();
			$('.main-header').removeClass('header-fixed');
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function caretMenu(){
	$('.desktop-menu .nav .menu li').each( function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
		}
	});
}